<template lang="pug">
  .car-class-definition
    .car-class-name(
      :class="kebabCase(currentMode)"
    )
      | {{ currentPlan.car_class_name }}
      AppTooltip.m-r-10(
        v-if="isTooltipShown"
        icon="exclamation-triangle"
        :title="tooltipTitle"
        type="warning"
      )
    .rental-periods(v-if="!isWrongInsurance")
      .rental-period(
        v-for="{ title } in rentalPeriods"
        :class="{ 'with-recommended-prices': !isManualMode && !isAutoSetPrices }"
      )
        .title {{ title }}
</template>

<script>
  import { currentMode, isManualMode, isAutoSetPrices } from "@/helpers/price-calendar"
  import { PRICE_MANAGEMENT_ALLOWED_OTA_IDS } from "@/config/constants"
  import { kebabCase } from "lodash-es"

  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      currentPlan: {
        type: Object,
        default: () => new Object()
      },
      filters: Object,
      rentalPeriods: Array
    },

    data() {
      return {
        rakutenOtaId: PRICE_MANAGEMENT_ALLOWED_OTA_IDS.rakutenId
      }
    },

    methods: {
      kebabCase
    },

    computed: {
      currentMode,
      isManualMode,
      isAutoSetPrices,

      tooltipTitle({ insurance }) {
        return this.$t(`price_calendar.calendar.${insurance}_tooltip`)
      },

      insurance() {
        return this.filters.insurance.title
      },

      isTooltipShown({ isRakutenOta, isWrongInsurance }) {
        return isRakutenOta && isWrongInsurance
      },

      isRakutenOta() {
        return this.filters.ota.id === this.rakutenOtaId
      },

      isWrongInsurance() {
        return this.currentPlan.insurance !== this.insurance
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .car-class-definition
    border-right: 1px solid $default-purple-light-line
    box-sizing: content-box
    width: 240px

    .car-class-name
      align-items: center
      box-sizing: border-box !important
      border-bottom: 1px solid transparent
      color: $default-purple
      display: flex
      font-weight: 600
      height: 25px
      justify-content: space-between
      min-width: 240px
      width: 240px

      .chevron
        color: $icon-color
        cursor: pointer
        margin-right: 10px
        font-weight: 200

      &.manual-mode,
      &.ai-mode
        height: 50px

      &.auto-price-mode
        height: 75px

    .rental-periods
      background-color: $default-gray-light
      width: 240px

      .rental-period
        align-items: center
        border-bottom: 1px solid $border-element-color
        color: $default-black
        display: flex
        justify-content: space-between
        height: 40px
        min-width: 240px

        .title
          padding-left: 10px
          padding-top: 5px

        .row-titles
          .current-price,
          .recommended-price,
          .applying-price
            align-items: center
            color: $default-gray
            display: flex
            height: 40px
            font-size: 0.75em
            justify-content: flex-end
            margin-right: 5px

      .with-recommended-prices
        height: 120px !important
</style>
